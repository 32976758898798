<!--  -->
<template>
    <div class='page-task-task-subject-summary flex-center'>
        <component-bcg url="task/c1.png"></component-bcg>
        <div class="bcg">
            <div class="board">
                <div class="task-room-bcg">
                    <div class="task-room flex-center" >
                        <!-- <span class="text-large-c text-purple-color bold room">{{`G${routeDataCul.courseId}综合练习`}}</span> -->
                        <span class="text-large-c text-purple-color bold room">{{`综合练习`}}</span>
                    </div>
                </div>
                <div class="task-content-bcg">
                    <div class="task-content" v-show="!dataLength&&loadingFlag">
                        <div class="content-mark"></div>
                        <div class="items-empty flex-center max-size ">
                            <span class="text-normal bold title">{{'题库暂未开放，敬请期待~'}}</span>
                        </div>
                    </div>
                    <div class="task-content" v-show="!dataLength&&!loadingFlag">
                        <div class="content-mark"></div>
                        <div class="items-empty flex-center max-size ">
                            <span class="text-normal bold title">{{'题库正在紧急加载 稍等片刻~'}}</span>
                        </div>
                    </div>
                    <div class="task-content" v-show="dataLength">
                        <div class="content-mark"></div>
                        <div class="items mini-scrollbar a" id="task-subject-section-a" @scroll="scrolLoad" ref="commentContent">
                            <PullRefresh v-model="isLoading" @refresh="onRefresh">
                                <template #pulling>
                                    <span class="color bold loading">下拉即可刷新...</span>
                                </template>
                                <template #loosing>
                                    <span class="color bold loading">释放即可刷新...</span>
                                </template>
                                <template #loading>
                                    <span class="color bold loading">加载中...</span>
                                </template>
                                <div class="items-box" ref="commentBox">
                                    <div class="item padding" v-for="(v,i) in dataList" :key="i">
                                        <div class="item-bcg scale-button" @click="gotoSection(v)">
                                            <div class="tag-bcg" v-if="v.tagFlag">
                                                <span class="text-mini-b bold b">{{'上次练到这'}}</span>
                                            </div>
                                            <div class="result-box text-yel-color" :class="v.correct ? 'right': 'wrong'" v-if="v.userAnswer"></div>
                                            <div class="img-box">
                                                <img class="board-img" :src="v.mediaPath" v-if="v.mediaPath">
                                                <img class="board-img" :src="`${PARAMS.assetsUrl}/img/v2_0/task/board9.png`" v-else>
                                            </div>
                                            <div class="item-title">
                                                <span class="text-mini-bb bold b text-yel-color" v-if="!v.userAnswer">{{`第${v.page}页第${v.index}题-未作答`}}</span>
                                                <span class="text-mini-bb bold b text-yel-color" :class="{right: v.correct}" v-else-if="v.correct">{{`第${v.page}页第${v.index}题-作答正确`}}</span>
                                                <span class="text-mini-bb bold b text-yel-color" :class="{wrong: !v.correct}" v-else>{{`第${v.page}页第${v.index}题-作答错误`}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <loading ref="loading" :loading.sync="loading" color="#D2B59A"></loading>
                                </div>
                            </PullRefresh>
                        </div>
                        <div class="items mini-scrollbar b" id="task-subject-section-b">
                            <div class="items-box">
                                <div class="item padding" v-for="(v,i) in dataList" :key="i">
                                    <div class="item-bottom-bcg scale-button">
                                        <div class="tag-bcg" v-if="v.tagFlag">
                                            <span class="text-mini-b bold b">{{'上次练到这'}}</span>
                                        </div>
                                        <div class="result-box text-yel-color" :class="v.correct ? 'right': 'wrong'" v-if="v.userAnswer"></div>
                                        <div class="img-box">
                                        </div>
                                        <div class="item-title">
                                            <span class="text-mini-bb bold b text-yel-color" v-if="!v.userAnswer">{{`第${v.page}页第${v.index}题-未作答`}}</span>
                                            <span class="text-mini-bb bold b text-yel-color" :class="{right: v.correct}" v-else-if="v.correct">{{`第${v.page}页第${v.index}题-作答正确`}}</span>
                                            <span class="text-mini-bb bold b text-yel-color" :class="{wrong: !v.correct}" v-else>{{`第${v.page}页第${v.index}题-作答错误`}}</span>
                                        </div>
                                    </div>
                                </div>
                                <loading ref="loading" :loading.sync="loading" color="#D2B59A"></loading>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import com from '@/views/task/page/section.vue'
    import loading from '@/views/history/components/loading.vue'
    import { PullRefresh } from 'vant';

    export default {
        name: '',
        props: {
        },
        extends: com,
        data() {
            return {
                tagTypeVal: 2,
                page: 0,
                size: 16,
                listCount: 0,
                dataList: [],
                loading: false,
                isLoading: false,
            }
        },
        methods: {
            async pageInit(){
                this.dataList = []
                this.subjectExerciseList()
                this.eventSet()
            },
            toastMessage(message){
                setTimeout(() => {
                    this.$toast({message,duration: 2000});
                    this.isLoading = false;
                }, 1000);
            },
            dataListPage(val){
                val = val || []
                let list = this._common.deepCopy(val)
                for (const i in list) {
                    list[i].page = this.page + 1
                    list[i].index = Number(i) + 1
                }
                return list
            },
            async onRefresh() {
                let {dataList} = this
                let {routeDataCul,size} = this
                let page = dataList[0].page - 1
                if(page<=0) return this.toastMessage('已到达顶部')
                page--
                this.page = page
                let opt = {
                    school_id: this.api.PARAMS.school_id,
                    course_id: routeDataCul.courseId,
                    page,
                    size,
                }
                let res = await this.api.userExerciseList(opt)
                if(res){
                    let list = this.dataListPage(res)
                    list = this.dataHandle(list)
                    var arr = this.dataList
                    for (var i = list.length-1; i >= 0; i--) {
                        arr.unshift(list[i])
                    }
                    this.dataList = arr
                    this.toastMessage('刷新成功')
                }
            },
            async scrolLoad(){
                let {dataList,loading,routeDataCul,page,size,dataLength:length,listCount:total} = this
                if(loading == true) return
                if(!this.$refs['commentContent']) return
                let index = this.$refs['commentContent'].scrollTop
                let contentHeight = this.$refs['commentContent'].offsetHeight
                let boxHeight = this.$refs['commentBox'].offsetHeight
                if((boxHeight - index - 31) > contentHeight) return
                this.loading = true
                if(length==total) {
                    this.loading = false
                    return
                }
                page = dataList[dataList.length-1].page
                let opt = {
                    school_id: this.api.PARAMS.school_id,
                    course_id: routeDataCul.courseId,
                    page,
                    size,
                    noLoad: true
                }
                let res = await this.api.userExerciseList(opt)
                if(res){
                    this.listCount = res.length
                    this.page = page
                    let list = this.dataListPage(res)
                    let r = this.dataHandle(list)
                    this.dataList = this.dataList.concat(r)
                }
                this.loading = false
            },
            dataHandle(val){
                let list = val || []
                list = this._common.deepCopy(list)
                let tagItem = ''
                for (const i in list) {
                    if(list[i].latestAnswer && i < list.length-1) tagItem = parseInt(i)+1
                }
                if(tagItem) list[tagItem].tagFlag = true
                return list
            },
            async subjectExerciseList(val){
                val = val || {}
                let {routeDataCul,page,size} = this
                let opt = {
                    school_id: this.api.PARAMS.school_id,
                    course_id: routeDataCul.courseId,
                    page: val.page || page,
                    size,
                }
                let res = await this.api.userExerciseList(opt)
                if(!res) return
                let list = this.dataListPage(res)
                this.dataList = this.dataHandle(list)
            },
            eventSet(){
                this.eventOff()
                this._common.eventSet('taskSectionsBackFun', e => {
                    this.back()
                })
            },
            eventOff(){
                this._common.eventRemove('taskSectionsBackFun')
            },
            back(){
                let {routeDataCul,tagTypeVal} = this
                let name = 'task-summary'
                let params = {
                    headVal: routeDataCul.courseId,
                    subject: routeDataCul.courseId,
                }
                let query = { tagTypeVal }
                let type = 'replace'
                this._routeHand.goto({name, type, params, query})
            },
            gotoSection(opt){
                let {routeDataCul,size} = this 
                let name = 'task-subject-actions'
                let type = 'replace'
                let params = {
                    ...routeDataCul,
                    exerciseId: opt.id,
                    id: opt.id,
                    page: opt.page-1,
                    size
                }
                params = {
                    routeData: this._common.encodeData(params)
                }
                this._routeHand.goto({name, type, params})
            },
        },
        created() {
        },
        components: {
            loading,
            PullRefresh,
        },
        computed: {
            dataLength(){
                let {dataList} = this
                return dataList.length
            },
        },
        watch: {
            $route: {
                handler(){
                    let {routeDataCul} = this
                    if(routeDataCul.page == null) return
                    this.page = routeDataCul.page
                },
                deep: true,
                immediate: true
            }
        },
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less'>
    
    .page-task-task-subject-summary{
        @text-yel-color-empty:#8D7461;
        @text-yel-color:#8D7461;
        @text-purple-color:#59578E;
        @text-red-color:#991B3D;
        @text-pur-color:#6143AF;
        .text-pur-color{
            color: @text-pur-color;
        }
        .text-red-color{
            color: @text-red-color;
        }
        .text-yel-color{
            color: @text-yel-color;
        }
        .text-purple-color{
            color: @text-purple-color;
        }
        .chapter-result-box{
            position: relative;
            width: 285px;
            height: 323px;
        }
        .bcg{
            .background-img-max(url('@{assetsUrlV2_0}/task/c2.png'));
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 638px;
            height: 375px;
            .board{
                .background-img-max(url('@{assetsUrlV2_0}/task/c3.png'));
                width: 621px;
                height: 360px;
                position: absolute;
                top: 10px;
                left: 10px;
                .task-room-bcg{
                    .background-img-max(url('@{assetsUrlV2_0}/task/c4.png'));
                    width: 209px;
                    height: 43px;
                    position: absolute;
                    top: 2px;
                    left: 206px;
                    .task-room{
                        .background-img-max(url('@{assetsUrlV2_0}/task/c5.png'));
                        width: 193px;
                        height: 43px;
                        position: absolute;
                        top: -11px;
                        left: 7px;
                        .room{
                            position: relative;
                            top: -1px;
                        }
                    }
                }
                .task-content-bcg{
                    .background-img-max(url('@{assetsUrlV2_0}/task/c22.png'));
                    width: 580px;
                    height: 309px;
                    position: absolute;
                    top: 45px;
                    left: 21px;
                    .task-content{
                        width: 521px;
                        height: 245px;
                        position: absolute;
                        top: 25px;
                        left: 26px;
                        .background-img-max(url('@{assetsUrlV2_0}/task/c24.png'));
                        .items-empty{
                            .title{
                                color: @text-yel-color-empty;
                            }
                        }
                        .content-mark{
                            // .background-img-max(url('@{assetsUrlV2_0}/task/c23.png'));
                            width: 521px;
                            height: 245px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 2;
                            pointer-events: none;
                            border-radius: 6px;
                            box-shadow: inset 0px 0px 4px 3px rgba(215,183,169,0.75);
                        }
                        .item.a{
                            pointer-events: none;
                            z-index: 1;
                        }
                        .items.b{
                            pointer-events: none;
                            z-index: 3;
                            .items-box{
                                visibility: hidden;
                            }
                        }
                        .items{
                            width: 525px;
                            height: 245px;
                            position: absolute;
                            .items-box{
                                width: 100%;
                                display: flex;
                                flex-wrap: wrap;
                                padding: 0 12px;
                                padding-bottom: 16px;
                            }
                            .loading.color{
                                color: #D2B59A;
                                font-size: 12px;
                                transform: scale(0.8);
                            }
                            .item.padding:nth-child(1){
                                margin-top: 16px;
                            }
                            .item.padding:nth-child(2){
                                margin-top: 16px;
                            }
                            .item.padding:nth-child(3){
                                margin-top: 16px;
                            }
                            .item.padding:nth-child(4){
                                margin-top: 16px;
                            }
                            .item.padding{
                                margin-top: 10px;
                                padding-bottom: 6px;
                            }
                            .item{
                                // .background-img-max(url('@{assetsUrlV2_0}/task/c13.png'));
                                width: 25%;
                                position: relative;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-top: 16px;
                                .item-bcg{
                                    width: 106px;
                                    height: 132px;
                                    border-radius: 10px;
                                    .background-img-max(url('@{assetsUrlV2_0}/task/c28.png'));
                                    position: relative;
                                    // box-shadow: 0px 0px 5px 3px #d48c50 inset;
                                    // background: #fff6eb;
                                    .tag-bcg{
                                        .background-img-max(url('@{assetsUrlV2_0}/task/c29.png'));
                                        width: 60px;
                                        height: 15px;
                                        position: absolute;
                                        left: 0;
                                        right: 0;
                                        margin: auto;
                                        z-index: 1;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        span{
                                            color: #FFFFFF;
                                        }
                                    }
                                    .result-box.right{
                                        .background-img-max(url('@{assetsUrlV2_0}/task/result_right.png'));
                                    }
                                    .result-box.wrong{
                                        .background-img-max(url('@{assetsUrlV2_0}/task/result_wrong.png'));
                                    }
                                    .result-box{
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        width: 20px;
                                        height: 20px;
                                        background: #ffffff;
                                        border-radius: 10px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        z-index: 1;
                                    }
                                    .img-box{
                                        width: 90px;
                                        height: 90px;
                                        border-radius: 5px;
                                        background: #fbd67b;
                                        position: absolute;
                                        top: 8px;
                                        left: 0;
                                        right: 0;
                                        margin: 0 auto;
                                        .board-img{
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                    .item-title{
                                        position: absolute;
                                        top: 108px;
                                        left: 0;
                                        right: 0;
                                        margin: 0 auto;
                                        display: flex;
                                        justify-content: center;
                                    }
                                }
                                .item-bottom-bcg{
                                    width: 106px;
                                    height: 132px;
                                    border-radius: 10px;
                                    // .background-img-max(url('@{assetsUrlV2_0}/task/c28.png'));
                                    position: relative;
                                    // box-shadow: 0px 0px 5px 3px #d48c50 inset;
                                    // background: #fff6eb;
                                    .tag-bcg{
                                        // .background-img-max(url('@{assetsUrlV2_0}/task/c29.png'));
                                        width: 60px;
                                        height: 15px;
                                        position: absolute;
                                        left: 0;
                                        right: 0;
                                        margin: auto;
                                        z-index: 1;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        span{
                                            color: #FFFFFF;
                                        }
                                    }
                                    .result-box.right{
                                        // .background-img-max(url('@{assetsUrlV2_0}/task/result_right.png'));
                                    }
                                    .result-box.wrong{
                                        // .background-img-max(url('@{assetsUrlV2_0}/task/result_wrong.png'));
                                    }
                                    .result-box{
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        width: 20px;
                                        height: 20px;
                                        background: #ffffff;
                                        border-radius: 10px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        z-index: 1;
                                    }
                                    .img-box{
                                        width: 90px;
                                        height: 90px;
                                        border-radius: 5px;
                                        background: #fbd67b;
                                        position: absolute;
                                        top: 8px;
                                        left: 0;
                                        right: 0;
                                        margin: 0 auto;
                                        .board-img{
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                    .item-title{
                                        position: absolute;
                                        top: 108px;
                                        left: 0;
                                        right: 0;
                                        margin: 0 auto;
                                        display: flex;
                                        justify-content: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        /deep/.com-confirm-button{
            width: 100%;
            height: 100%;
        }
        // 滚动条
        .mini-scrollbar::-webkit-scrollbar{
            width: 10px;
            // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
        }
        .mini-scrollbar-x::-webkit-scrollbar{
            height: 4px;
        }
        .mini-scrollbar::-webkit-scrollbar-thumb, .mini-scrollbar-x::-webkit-scrollbar-thumb{
            border-radius: 10px;
            // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            // background: rgba(0,0,0,0.2);
            .background-img-max(url('@{assetsUrlV2_0}/task/c26.png'));
            // background: #FFFAE6;
            // background: url('@{assetsUrlV2_0}/avatar/c30.png') no-repeat;
            // background-position: center;
            
        }
        .mini-scrollbar::-webkit-scrollbar-track-piece{
            // background: red;
            // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
        }
        .mini-scrollbar::-webkit-scrollbar-thumb::before{
            content: '';
            .background-img-max(url('@{assetsUrlV2_0}/task/c26.png'));
        }
        .mini-scrollbar::-webkit-scrollbar-track, .mini-scrollbar-x::-webkit-scrollbar-track{
            // -webkit-box-shadow: inset 0 0 5px #C7946A;
            border-radius: 10px;
            // background: rgba(178,123,85,0.1);
            // background: #B27B55;
            background-image: linear-gradient(to right, #D2B7A2, #DDCDB8, #D2B7A2);
            // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
        }
        .mini-scrollbar{
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
    
</style>